
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















/* stylelint-disable declaration-no-important */
.upload__help {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: auto;
  margin-top: auto;
}

.upload__label {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

::v-deep {
  .vue-file-agent.file-input-wrapper {
    display: flex;
    min-height: 50px;
    min-width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    box-shadow: inset 0 0px 3px rgba($c-black, 0.1);

    strong {
      display: block;
    }
  }

  .file-preview-wrapper {
    z-index: 0;
    position: relative !important;
    margin-bottom: 0.5rem !important;
    transform: none !important;

    &::before {
      display: none !important;
    }
  }

  .file-preview {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    height: auto !important;
    width: max-content;

    span:not(.file-progress) {
      position: static !important;
    }

    &::before {
      left: 0 !important;
      background: $c-white !important;
    }

    &::after {
      display: none !important;
    }
  }

  .file-icon {
    display: none !important;
  }

  .file-input {
    width: 85%;
  }

  .file-name {
    order: 1;
    max-width: 300px;
    margin-left: 5px;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .file-ext {
    display: none !important;
  }
  .file-size {
    display: none !important;
  }

  .image-dimension {
    display: none !important;
  }

  .file-delete {
    order: 4;
    margin-left: auto;

    svg {
      width: 20px !important;
      height: 20px !important;
      background: $c-gray;
      border: 1px solid $c-gray;
      border-radius: 50%;

      path {
        color: $c-black;
      }
    }
  }

  .file-preview-img {
    filter: none;
  }

  .thumbnail {
    display: none !important;
  }

  // stylelint-disable-next-line
  .vue-file-agent .file-preview .file-delete {
    display: flex;
    align-items: center;
    height: 100%;
  }

  [helperclass='active-sorting-item'] {
    max-width: 100%;
    > div {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
  }
}
