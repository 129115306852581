
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





















































































a.vgt-actions {
  width: 100% !important;
  text-align: center;
}
